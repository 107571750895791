<template>
    <div>
        <b-card-actions ref="providentialIncome" no-actions :isTable="true">

            <div class="m-2">
                <!-- Table Top -->
                <b-row>
                    <!-- Per Page -->
                    <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                        <b-button @click="$router.push({name: 'apps-inventories-provisional-income-add'})" variant="primary">
                            <feather-icon icon="PlusIcon" size="16" />
                            Nuevo Ingreso
                        </b-button>
                    </b-col>

                    <!-- Search -->
                    <b-col cols="12" md="6">
                        <div class="d-flex align-items-center justify-content-end">
                            <b-input-group>
                                <b-form-input v-model="searchQuery" size="sm" placeholder="Buscar por almacen, articulo o clave" 
                                @keydown.enter="getProvisionalIncomes"/>
                                <b-input-group-append>
                                    <b-button v-if="!searchQuery" @click="getProvisionalIncomes" size="sm" variant="primary">
                                        <feather-icon icon="SearchIcon" />
                                        Buscar
                                    </b-button>
                                    <b-button v-else @click="resetSearch" size="sm" variant="outline-danger">
                                        <feather-icon icon="XIcon" />
                                        Limpiar
                                    </b-button>
                                </b-input-group-append>
                            </b-input-group>
                        </div>
                    </b-col>
                </b-row>
            </div>

            <!-- <b-row>
                <b-col cols="12"> -->
                    <b-table :items="provisionals" :fields="fields" responsive hover 
                        class="text-small small b-table-small"
                        :sticky-header="false"
                        :no-border-collapse="true"
                        small
                        primary-key="id"
                        show-empty
                        empty-text="No se encontraron resultados"
                        :busy.sync="loading">

                        <template slot="empty">
                            <div v-if="loading" class="d-flex justify-content-center mb-1">
                                <b-spinner
                                    style="width: 3rem; height: 3rem"
                                    class="mr-1"
                                    label="Loading..."
                                    variant="primary"
                                />
                            </div>
                        </template>

                        <template #cell(almacen_Id)="data">
                            {{ data.item.almacen_Id }}
                        </template>

                        <template #cell(fecha_Docto)="data">
                            <span class="text-capitalize">{{ formatDate(data.item.fecha_Docto) }}</span>
                        </template>

                        <template #cell(fecha_Alta)="data">
                            <span class="text-capitalize">{{ formatDate(data.item.fecha_Alta) }}</span>
                        </template>

                        <template #cell(actions)="data">
                            <!-- <div class="demo-inline-spacing"> -->
                                <b-button @click="revertItem(data.item)" size="sm" v-if="data.item.estatus === 'ACTIVO' && data.item.docto_In_Id" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="warning">
                                    Revertir
                                </b-button>

                                <b-button size="sm" v-if="data.item.estatus === 'ACTIVO' && !data.item.docto_In_Id" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="danger">
                                    Sincronizar
                                </b-button>
                            <!-- </div> -->
                        </template>
                    </b-table>

                    <!-- Pagination -->
                    <div v-if="totalRecords > 0" class="mx-2 mb-2">
                        <b-row>
                            <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
                                <span class="text-muted">{{ resolvePaginationTranslate(dataMeta) }}</span>
                            </b-col>
                            <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
                                <div class="mt-2 demo-spacing-0">
                                    <b-pagination v-model="currentPage" :total-rows="totalRecords" :per-page="pageSize"/>
                                </div>
                            </b-col>
                        </b-row>
                    </div>
                <!-- </b-col>
            </b-row> -->
        </b-card-actions>

        <!-- <div v-if="!loading" class="buy-now">
            <b-button @click="$router.push({name: 'apps-inventories-provisional-income-add'})" size="lg" variant="primary">
                <feather-icon icon="PlusIcon" size="16" />
                Nuevo Ingreso
            </b-button>
        </div> -->
    </div>
</template>

<script>

import {
    BRow, BCol, BCard, BCardBody, BTableLite, BCardText, BButton, BAlert, BLink, BOverlay, BSpinner, BTable, BFormCheckbox, BFormGroup, BFormInput,
    BModal, VBModal, BSidebar, BForm, BIcon, BInputGroup, BInputGroupAppend, BInputGroupPrepend, BFormTextarea, BFormTags, BAvatar, BPagination, BFormSelect,
    BBadge, BCardSubTitle, BCardTitle, BTabs, BTab, BTooltip, VBToggle,
} from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import Ripple from "vue-ripple-directive";
import store from '@/store'

import InventoriesProvider from '@/providers/Inventories'
const InventoriesResource = new InventoriesProvider()

export default {
    name: "ProvisionalIncome",
    components: {
        BRow, BCol, BCard, BCardBody, BTableLite, BCardText, BButton, BAlert, BLink, BOverlay, BSpinner, BTable, BFormCheckbox, BFormGroup, BFormInput,
        BModal, VBModal, BSidebar, BForm, BIcon, BInputGroup, BInputGroupAppend, BInputGroupPrepend, BFormTextarea, BFormTags, BAvatar, BPagination, BFormSelect,
        BBadge, BCardSubTitle, BCardTitle, BTabs, BTab, BTooltip,

        //
        BCardActions,
    },
    directives: {
        Ripple,
    },
    data() {
        return {
            loading: false,
            fields: [
                { key: 'fecha_Docto', label: 'Fecha Mov', thClass: 'text-small', tdClass: 'text-small', tdStyle: 'font-size: 9px!important;'},
                { key: 'almacen', label: 'Almacen', thClass: 'text-small', tdClass: 'text-small' , tdStyle: 'font-size: 9px!important;'},
                { key: 'articulo', label: 'Articulo', thClass: 'text-small', tdClass: 'text-small' , tdStyle: 'font-size: 9px!important;'},
                { key: 'clave_Articulo', label: 'Clave Articulo', thClass: 'text-small', tdClass: 'text-small' , tdStyle: 'font-size: 9px!important;'},
                { key: 'um', label: 'U. Compra', thClass: 'text-small', tdClass: 'text-small' , tdStyle: 'font-size: 9px!important;'},
                { key: 'unidades', label: 'Cantidad', thClass: 'text-center text-small', tdClass: 'text-center text-small', tdStyle: 'font-size: 9px!important;'},
                { key: 'costo_Unitario', label: 'C. Unit',  thClass: 'text-center text-small', tdClass: 'text-center text-small' , tdStyle: 'font-size: 9px!important;'},
                { key: 'costo_Total', label: 'Costo Total', thClass: 'text-center text-small', tdClass: 'text-center text-small' , tdStyle: 'font-size: 9px!important;'},
                { key: 'existencias', label: 'Existencia',  thClass: 'text-center text-small', tdClass: 'text-center text-small' , tdStyle: 'font-size: 9px!important;'},
                { key: 'fecha_Alta', label: 'Fecha Alta', thClass: 'text-small', tdClass: 'text-small' , tdStyle: 'font-size: 9px!important;'},
                { key: 'estatus', label: 'Estatus', thClass: 'text-small', tdClass: 'text-small' , tdStyle: 'font-size: 9px!important;'},
                { key: 'actions', label: '' , tdStyle: 'font-size: 9px!important;'},
            ],
            provisionals: [],
            currentPage: 1,
            pageSize: 10,
            totalPages: 0,
            totalRecords: 0,
            searchQuery: '',
        };
    },
    computed: {
        dataMeta() {
            const localItemsCount = _.size(this.provisionals);
            return {
                from:
                    this.pageSize * (this.currentPage - 1) +
                    (localItemsCount ? 1 : 0),
                to: this.pageSize * (this.currentPage - 1) + localItemsCount,
                of: this.totalRecords,
            };
        },
    },
    async mounted() {
        await this.getProvisionalIncomes()
    },
    methods: {
        resetSearch() {
            this.searchQuery = null
        },
        revertItem(item) {
            console.log(item)
            this.$swal({
                title: 'Generar Salida',
                html: `Se va generar la salida de la siguiente entrada <strong>${item.folio}</strong> <br><br><p>¿Desea continuar?</p>`,
                icon: 'warning',
                showCancelButton: true,                
                confirmButtonText: 'Continuar',
                cancelButtonText: 'Cancelar',
                customClass: {
                    confirmButton: "btn btn-primary",
                    cancelButton: "btn btn-outline-danger ml-1",
                },
                buttonsStyling: false,
                showClass: {
                    popup: "animate__animated animate__flipInX",
                },
                showLoaderOnConfirm: true,
                preConfirm: async () => {
                    this.loading = true
                    let payload = []
                    payload.push({
                        id: item.id,
                        empresa_Id: item.empresa_Id,
                        surcusal_Id: item.surcusal_Id,
                        usuario_Id: "string",
                        almacen_Id: item.almacen_Id,
                        almacen: item.almacen,
                        articulo_Id: item.articulo_Id,
                        articulo: item.articulo,
                        clave_Articulo: item.clave_Articulo,
                        um: item.um,
                        unidades: item.unidades,
                        costo_Unitario: item.costo_Unitario,
                        costo_Total: item.costo_Total,
                        existencias: item.existencias,
                        descripcion: `Salida a entrada provisional. ${item.folio}`,
                        estatus: "REVERSA",
                        fecha: "2024-09-18T01:29:51.146Z",
                        fecha_Alta: "2024-09-18T01:29:51.146Z",
                        fecha_Actualizacion: "2024-09-18T01:29:51.146Z",
                    })

                    try {
                        this.loading = true
                        const { data } = await InventoriesResource.addProvisionalIncome(payload)
                        this.loading = false
                        if (data.isSuccesful) {
                            this.success(data.message)
                        } else {
                            this.$swal.showValidationMessage(data.message)
                        }
                        await this.getProvisionalIncomes()
                    }catch(e) {
                        this.loading = false
                        this.handleResponseErrors(e)
                        this.$swal.showValidationMessage(`
                            Request failed: ${e}
                        `);
                    }

                },
                allowOutsideClick: () => !Swal.isLoading(),
            });
        },
        async getProvisionalIncomes() {
            this.provisionals = []
            const query = {
                fechaIncio: null,
                fechaFin: null,
                numeroPagina: this.currentPage,
                tamanoPagina: this.pageSize,
                status: 'ACTIVO',
                search: this.searchQuery
            }

            try {
                this.loading = true
                const { data } = await InventoriesResource.getProvisionalIncomes(query) 
                this.loading = false                
                this.provisionals = data.data
                this.totalRecords = data.totalRecords
                
            }catch(e) {
                this.loading = false
                this.handleResponseErrors(e)
            }finally{
                this.loading = false
            }
        }
    },
    watch: {
        searchQuery(value) {
            if (!value) {
                this.getProvisionalIncomes()
            }
        },
        currentPage(value) {
            this.getProvisionalIncomes();
        },
        pageSize(value) {
            this.getProvisionalIncomes();
        },
    },
    setup() {
        
    },
}
</script>

<style>
.b-table-sticky-header {
    overflow-y: auto;
    min-height: 400px;
}

.table-wrapper {
  height: 600px; /* Ajusta la altura según tus necesidades */
  overflow-y: auto;
}

</style>